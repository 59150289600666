

html, body {
	height: 100%;
}

body {
	text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}

a {
	@include hover-focus-active {
		text-decoration: none;
	}
	transition: color 0.2s, background-color 0.2s;
}

p {
	line-height:1.5rem;
	color:$body-color;
	font-weight:$body-font-weight;
	@include font-size(13);

	&:last-child {
		margin-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		@include font-size(14);
	}
}

img {
	max-width: 100%;
	height: auto;
}

video {
	width: 100%;
	height: auto;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-headings;
}
h1.page-title {
	@include font-size(70);

	@include media-breakpoint-up(md) {
		@include font-size(80);
	}

	@include media-breakpoint-up(lg) {
		@include font-size(100);
	}
}

h1.page-title-small {
	letter-spacing: 1px;
	@include font-size(40);
}

h1 {
	@include media-breakpoint-up(md) {
		@include font-size(48);
	}
}
h2 {

	@include font-size(24);
	text-transform: none;

	@include media-breakpoint-up(md) {
		@include font-size(34);
		text-transform: none;
	}
}
h3 {
	@include media-breakpoint-up(md) {
		@include font-size(48);
	}
}
h5 {
	font-family: $font-family-sans-serif-bold;
	text-transform: uppercase;
	@include font-size(11);
	letter-spacing:2px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

p {
	//padding: 0 4rem;
}

// classes

.wrap {
	overflow: hidden;
	position: relative;
	max-width: 100vw;
	opacity: 0;
    transition: opacity 0.5s;
	transition: 0.5s;
	margin-top:-2rem;
}

.accent {
	color: $brand-primary;
}

.block {
	display: block;
}

.cover-background {
	background-size: cover;
	background-position: center;
}

.page-main {
  padding-top: $grid-gutter-width;
  position: relative;
  background-color: $body-bg;
}

// logos

.vn-logo {
	
	.top, .mid1, .mid2, .bottom {
		transition: 0.4s;
		// transform-origin: center;
	}

	@include hover-focus-active {
		.top {
			// transform: rotate(-2deg);
		}
		.mid1, .mid1_bg {
			transform: translateY(10px);
		}
		.mid2, .mid2_bg {
			transform: translateY(-10px);
		}
		.bottom {
			// transform: scale(1.1);

		}
	}
}

.rainbow_line {
	max-width: 220px;
}

// Videos

.video-container {
	position: relative;
}

.play-controls {
	position: absolute;
	left: 50%;
	bottom: 50%;
	transform: translate(-50%, 50%);
	transition: 0.2s;

	color: $brand-primary;
	@include font-size(50);

	@include hover-focus-active {
		color: $brand-red-light;
	}

	&.playing {
		left: 20px;
		bottom: 30px;
		transform: translate(-50%, 50%) scale(0.5);
	}
}
