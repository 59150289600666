////////////////////////////////////
// Page Header
////////////////////////////////////

.page-header {
	position: relative;
	text-align: center;
	z-index: 100;
	@include media-breakpoint-up(md) {
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			pointer-events: none;
		}
	}
}

.top-gradient {
	background-image: linear-gradient(to top, rgba($body-color, 0) 0%, rgba($body-color, 0) 70%, rgba($body-color, 0.6));
}

.page-header__content {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	
	
	color: #fff;
}

////////////////////////////////////
// Page
////////////////////////////////////

.page {
	
	.page-subtitle {
		font-weight: 300;
		@include font-size(13);
		text-transform: uppercase;
		letter-spacing: 3px;
		font-family: $font-family-sans-serif;
		
		@include media-breakpoint-up(md) {
			@include font-size(14);
		}

	}

	.page-main {
		margin-bottom: 3rem;
		padding: 1rem 0;

		ul {
			margin-top:0.5rem;
			li {
				@include font-size(13);
				margin-bottom: 0.5rem;

				@include media-breakpoint-up(md) {
					@include font-size(14);
				}

				&:last-child {
					margin-bottom: 0rem;
				}
			}

		}
	}
}

.page-header__border {
		background-image: url(../images/slider-border.png);
		background-size: 1920px;
		background-position: center;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 51px;
		z-index: 100;
		pointer-events: none;
	}


////////////////////////////////////
// Contact Page
////////////////////////////////////
.page.contact {

	.page-main {
		margin-bottom: 0;
		min-height: 90vh;
		padding-top:2rem;
		padding-bottom:4rem;
		padding-left: 1rem;
		padding-right: 1rem;
		text-align:center;

		.xs-margin {
			margin-bottom: 2rem;
		}

		@include media-breakpoint-up(lg) {
			padding-top:160px;
			padding-bottom:0;
			text-align:left;

			.xs-margin {
				margin:0;
			}
		}
	}

	.contact__content {
		padding-top: 4rem;
		margin-top: 0rem;
		margin-bottom: 2rem;
		text-align:center;

		img {
			display: none;

			@include media-breakpoint-up(lg) {
				display: block;
				margin-bottom: 2rem;
			}
		}

		@include media-breakpoint-up(md) {
			padding-top: 10rem;
		}

		@include media-breakpoint-up(lg) {
			padding-top: 4rem;
			text-align:left;
			margin-top: 1rem;
		}
	}

	.contact__email {
		margin-bottom: 0;

		@include media-breakpoint-up(lg) {
			margin-bottom: 2rem;
		}

		a {
			color: $brand-orange-light;
		}
	}

	.contact__phone {
		margin-bottom: 1rem;
	}

	.contact__social {
		display: block;
		margin-top: 0rem;
		margin-bottom: 2rem;
		text-align: center;

		a {
			margin-right: 1rem;
			color: $brand-orange-light;
		}

		@include media-breakpoint-up(lg) {
			text-align: left;
			margin-bottom: 0;
		}
	}

	.contact-socials i {
		color: $brand-black;
		display: inline;
	}

	.col-md-6 {
		padding-right:1rem;
		padding-left:1rem;
	}

	.page-header img {
		height:300px;
		object-fit: cover;

		@include media-breakpoint-up(md) {
			height:auto;
		}
	}

	.contact__form {
		margin-top: 4rem;

		@include media-breakpoint-up(md) {
			margin-top: 0rem;
		}
	}

	input {
		border:1px solid $brand-black;
		@include font-size(13);
	}
	.gform_wrapper label.gfield_label {
		font-weight: 600;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-family: $font-family-headings;
		@include font-size(13);
	}
	.gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
	    font-size: inherit;
	    font-family: inherit;
	    padding: 12px 20px;
	    letter-spacing: normal;
	    @include font-size(15);
	}
    .gform_wrapper textarea.large {
        height:320px;
        width:100%;
        @include font-size(16);
        padding: 20px 20px;
    }

	input[type="submit"] {
	  font-size:.8rem !important;
	  background-color:$brand-black;
	  color: #fff;
	}

	.page-main {

		color: #fff;

		@include font-size(14);

		h1 {
			@include font-size(30);
			color:#fff;

			@include media-breakpoint-up(md) {
				@include font-size(60);
			}
		}
		h2 {
			@include font-size(50);
		}

		h3 {
			@include font-size(22);
			font-weight:600;
			margin-bottom:0.3rem;
		}

	}

}

////////////////////////////////////
// Agency Page
////////////////////////////////////

.page.agency {
	.page-header {
		margin-bottom: 0;
	}
	.page-header img {
		height: 300px;
    width: 100%;
		object-fit: cover;

		@include media-breakpoint-up(md) {
			height:auto;
		}
	}
	.page-main {
		margin-bottom: 0;
		padding: 0;
	}
}

.services__section-1-left {
	padding: 4rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
  justify-content: center;

	background-color: $body-color;
	@include font-size(36);
	font-weight: 200;
	line-height: 1.2;

	p,h2 {
		color: $body-bg;
		text-transform: none;
	}
}
.services__section-1-right {
	padding: 4rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
  	justify-content: center;
	background-color: $brand-black;

	p,h2 {
		color: $body-bg;
		text-transform: none;
	}
}
.services__section-2 {
	padding: 5rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
  	justify-content: center;

	text-align: left;
	@include font-size(16);
	font-weight: 500;

	@include media-breakpoint-up(md) {
		padding: 5rem 7rem;
	}
	@include media-breakpoint-up(lg) {
		padding: 8rem 7rem;
	}
	@include media-breakpoint-up(xl) {
		padding: 8rem 14rem;
	}
}
.services__section-3 {
	padding: 8rem 1rem;
	background-size: cover;
	background-attachment: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
  	justify-content: center;

	background-color: $body-color;
	color: #fff;
	@include font-size(24);
	font-weight: 200;

	h2 {
		font-weight: 200;
		@include font-size(24);

		@include media-breakpoint-up(lg) {
			@include font-size(34);
		}
	}

	p,h2 {
		color: $body-bg;
		text-transform: none;
	}

	@include media-breakpoint-up(md) {
		padding: 10rem 5rem;
		@include font-size(36);
	}
}

.services__section-4-left-title, .services__section-4-right-title {

	p {
		margin-top: 1rem;
		font-weight: 400;
		@include font-size (14);
		text-transform: uppercase;
		letter-spacing: 2px;
	}

	h3 {
		@include font-size (28);


		@include media-breakpoint-up(md) {
			@include font-size (20);
		}

		@include media-breakpoint-up(lg) {
			@include font-size (25);
		}
		text-transform: none;
	}
}
.services__section-4-left-title, .services__section-4-right-title {
	width: 100%;
	margin-bottom: 3rem;
	padding: 3rem 1rem 4rem;

	font-family: $font-family-headings;
	background-color: #eaeaec;
	color: $body-color;
	@include font-size(24);
	font-weight: 900;
	text-align: center;
}
.services__section-4-left, .services__section-4-right {
	display: flex;
	flex-direction: column;
	align-items: center;
  	padding: 0 0 3rem;
	background-color: $gray-lightest;
	color: $body-color;
	@include font-size(18);

	h3 {
		@include font-size(18);
		text-transform: uppercase;
		font-weight:600;
	}

	ul {
	    list-style: none;
	    padding:2rem 0 1rem;
	    margin:0;
	    @include font-size(15);
	}

	li { 
		margin-bottom: 1rem;
		padding-bottom:.3rem;
	    padding-left: 1em; 
	    text-indent: -.7em;
	    color: $body-color;
	}

	li:before {
	    content: "•";
	    padding-right: 1rem;
	    color: $brand-blue; /* or whatever color you prefer */
	}
}
.services__section-4-right-title {
	background-color: #eaeaec;
	color: $body-color;
}
.services__section-4-right {
	background-color: $gray-lightest;
	color: $body-color;

	li:before {
		color: $brand-blue;
	}
}


////////////////////////////////////
// Flexible Content
////////////////////////////////////

.flexible-content {
	p {
		color: $body-color;
		font-weight: $body-font-weight;
		line-height:1.5rem;
		@include font-size(13);

		@include media-breakpoint-up(md) {
			@include font-size(14);
		}
	}
	h2 {
		@include font-size(32);
		margin-bottom: 1rem;
		
		@include media-breakpoint-up(md) {
			@include font-size(42);
		}
	}
	h3 {
		@include font-size(32);
		margin-bottom:1.2rem;
		text-transform: none;
		text-align: left;
		color: $brand-black;
		letter-spacing:-1px;
	}
	img {
		width: 100%;
	}

	&__section {
		&--full-width-video {
			video {
				display: block;
			}
		}
		&--text {

		}
		&--mixed {
			
		}
	}

	&__column--padding {
		width: 100%;
		display: block;
		flex-direction: column;

		:first-child  {
			margin-bottom:.5rem;
		}

		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: row;
			position: relative;
			gap: 1rem;
			padding: 0;
			margin: 0;

			:first-child  {
				margin-bottom: 0rem;
			}
		}

	}

	&__column--text {
		padding: 4rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-breakpoint-up(md) {
			padding: 4rem 6rem;
		}
	}

	&__column--image {
		padding: 0;
		display: flex;
		flex-direction: column;
		position: relative;
		// align-items: stretch;

		img {
			display: block;
		  object-fit: cover;
		  // min-height: 100%;
		  // height: auto;
		  // max-width: 100%;
		}
	}

	&__section--one-column {
		.wysiwyg {
			padding: 5.5rem 2rem;
		}
	}

	&__section--full-width-image {
		.col-12 {
			padding: 0;
		}
	}

	&__section--four-columns {
		.col-12 {
			padding: 4rem 2rem;
			&:nth-child(even) {
				background-color: $gray-light;
			}
		}
	}

	&__section--white {
		background-color: #fff;
	}

	&__section--drop-shadow {
		margin: 0 0 0rem 0;
		box-shadow: 1rem 1rem 8rem rgba(0, 0, 0, 0.06);
	}

	&__section--margin-bottom {
		margin-bottom: 4rem;
		@include media-breakpoint-up(md) {
			margin-bottom: 7rem;
		}
	}

	&__section--white-space {
		padding-left: 2rem;
		padding-right: 2rem;
		@include media-breakpoint-up(md) {
			padding-left: 4rem;
			padding-right: 4rem;
		}
	}
}

////////////////////////////////////
// 404
////////////////////////////////////

.not-found {
	margin-top: 40vw;
	margin-bottom: 40vw;

	@include media-breakpoint-up(md) {
	margin-top: 15vw;
	margin-bottom: 15vw;
	}
	h1 {
		@include font-size (140);
		line-height: 0.9;
		color:$white;

		@include media-breakpoint-up(md) {
			@include font-size (300);
		}
	}
	h2 {
		color:$white;
		@include font-size (24);

		@include media-breakpoint-up(md) {
			@include font-size (40);
		}
	}
	h3 {
		color:$white;
		@include font-size (13);

		@include media-breakpoint-up(md) {
			@include font-size (16);
		}
	}
	h4 {
		@include font-size (13);
		font-weight: 400;
		letter-spacing: 2px;
		font-weight: 600;
		margin-top:1.5rem;

		@include media-breakpoint-up(md) {
			@include font-size (16);
		}
	}
	strong {
		font-weight: 600;
	}
}
.error404{
	.wrap {
	background-image: linear-gradient(to top, rgba($body-color, 2) 0%, rgba($body-color, 0.7) 30%, rgba($body-color, 1)), url('../images/404-bg.gif');
	background-repeat: repeat;
	background-position: 510px 0px;
	}
}

