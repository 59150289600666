.button {
	display: inline-block;
	height: 52px;
	padding: 0 3rem;
	font-family: $font-family-sans-serif-bold;
	background-color: $brand-primary;
	border: none;
	margin: 0 0.3rem;
	border-radius: 30px;
	transition: 0.2s;
  	cursor: pointer;
	line-height: 55px;
	color: #000;
	text-transform: uppercase;
	letter-spacing:1px;
	@include font-size(10);

	@include hover-focus-active {
		color: #fff;
		outline: 0;
	}

	@include media-breakpoint-up(md) {
		width: auto;
		margin: 0 0.3rem;
	}

  &--dark {
    background-color: $body-color;

    color: #fff;

    @include hover-focus-active {
      background-color: $brand-primary;

      color: $body-color;
    }
  }

  &--mobile-compressed {
    letter-spacing: 1px;

    @include media-breakpoint-up(md) {
      letter-spacing: 2px;
    }
  }
}

.button--tab {
	position: relative;
	background-color: transparent;
	width: 100%;
	margin: 0 0.3rem 0.5rem;
	padding: 0 1rem;
	font-family: $font-family-sans-serif-bold;
	border-radius: 0;
	letter-spacing: 2px;
	@include font-size(12);
	color: $white;

	&:nth-child(even) {
		background-color: #17171d;
	}

	@include media-breakpoint-up(md) {
		width: auto;
		margin: 0;
		padding: 0;
		color: $body-color;

		&:nth-child(even) {
			background: none;
		}
	}

	&:not(:last-child) {
		@include media-breakpoint-up(md) {
			padding: 0 1rem 0 0;
			margin-right: 1rem;

			&::after {
				content: '';
				display: block;
				height: 1rem;
				width: 1px;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				background-color: #fff;
			}
		}
	}

	&:nth-child(1) {
		&::after {
			background-color: $brand-red;
		}
	}

	&:nth-child(2) {
		&::after {
			background-color: $brand-orange;
		}
	}

	&:nth-child(3) {
		&::after {
			background-color: $brand-green;
		}
	}

	&:nth-child(4) {
		&::after {
			background-color: $brand-blue;
		}
	}

	@include hover-focus-active() {
		color: $brand-red-light;
	}

}

.disco-btn {
  background-image: linear-gradient(to left, 
  	$brand-red 21%, 
  	$brand-red-light 21%, 
  	$brand-red-light 33.3%, 
  	$brand-orange 33.3%, 
  	$brand-orange 50%, 
  	$brand-orange-light 50%, 
  	$brand-orange-light 66.7%, 
  	$brand-green 66.7%, 
  	$brand-green 80%, 
  	$brand-blue 80% );
  background-position: 0;
  background-size: 500%;
  transition: 0.8s;
  &:hover {
    background-position: 100%;
  }
}

.iphorm-submit-input-wrap {
	float: none;
	text-align: center;
}

.iphorm-submit-wrap button {
	@extend .button;
	@extend .disco-btn;

	margin: 0 auto;

	span {
		background: none;
		border: none;
	}
	em {
		background: none;
		transition: 0.3s;
	}

	@include hover-focus-active {
		span {
			background: none;
			border: none;
		}
		em {
			background: none;
			color: #fff;
		}
	}
}

