////////////////////////////////////
// Home
////////////////////////////////////

.home {
	text-align: center;

	// Hero

	section.hero {
		//height: 700px;
		overflow: hidden;
		position: relative;
		transform

		.hero__slider {
			position: absolute;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000;
		}

		.slide {
			position: relative;
		}
	}

	.slide__background {
		display: none;

		@include media-breakpoint-up(md) {
			display: block;
		}

		video {
			width: 100vw;
			display: block;
		}

    @include media-breakpoint-up(md) {
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

		background-image: linear-gradient(to top, rgba($body-color, 1), rgba($body-color, 0) 50%, rgba($body-color, 0) 70%, rgba($body-color, 0.8));
		pointer-events: none;
      }
    }

    
	}

	.slide__background--mobile {
		@include media-breakpoint-up(md) {
			display: none;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
	
			background-image: linear-gradient(to top, rgba($body-color, 1), rgba($body-color, 0) 60%);
			pointer-events: none;
		  }
	}

	.slide__content {
		position: absolute;
		bottom: 15%;
		width: 100%;
		padding: 0 0rem;
		transform: translateY(50%) translateY(-5rem);

		color: #fff;
		text-align: center;
		@include font-size(16);

		@media screen and (min-width: 375px) {
			@include font-size(20);
		}

		@include media-breakpoint-up(md) {
			text-align: center;
			bottom: 20%;
		}

		@include media-breakpoint-up(lg) {
			text-align: center;
			bottom: 20%;
			transform: translateY(50%) translateY(-5rem);
		}

		.type {
			font-family: $font-family-headings-light;
			margin: 0rem;
			display: inline-block;
			@include font-size(22);

			@include media-breakpoint-up(md) {
				margin-top: -1rem;
				@include font-size(28);
			}

			@include media-breakpoint-up(lg) {
				margin-top: -1rem;
				@include font-size(42);
			}
		}

		.tagline-container {
			margin: 0rem 0;
			line-height: 1.1;
			@include font-size(34);

			@include media-breakpoint-up(md) {
				@include font-size(45);
				margin: 0.5rem 0;
			}

			@include media-breakpoint-up(lg) {
				@include font-size(54);
			}
		}

		.tagline {
			display: inline-block;
			font-family: $font-family-sans-serif-bold;
			text-transform: none;
			@include font-size(42);

			@include media-breakpoint-up(md) {
				@include font-size(60);
			}

			@include media-breakpoint-up(lg) {
				font-size: 8vw;
			}
		}

		.title {
			font-weight: 600;
			font-family: $font-family-headings;
		}
		.description {
			@include font-size(13);
			@include media-breakpoint-up(md) {
				@include font-size(14);
			}
		}
	}

	// About

	section.about {
		padding-top: 6rem;
		padding-bottom: 6rem;
		padding-left: 2rem;
		padding-right: 2rem;

		@include font-size(16);

	}

	.about__vn-heading {

		font-family: $font-family-sans-serif;
		font-weight: 300;
		letter-spacing: 6px;
		@include font-size(14);
		text-transform: uppercase;
		color: #a4acc2;

		@include media-breakpoint-up(md) {
			@include font-size(16);
		}	
	}

	.about__tagline {
		margin-bottom: 1rem;
		margin-top: 2rem;

		font-family: $font-family-headings;
		@include font-size(28);
		line-height: 40px;
		color: $brand-black;
		text-transform: none;

		@include media-breakpoint-up(md) {
			@include font-size(40);
			line-height: 60px;
		}	

		@include media-breakpoint-up(lg) {
			@include font-size(52);
			line-height: 70px;
		}	
	}

	.about__mission {
		margin-bottom: 2rem;
	}

	.about__vn-intro-img {
		width: 100%;
		max-width: 500px;
	}
	
	// Featured Case Studies

	.featured-case-studies {
		position: relative;
		display: block;
		padding: 0 1.5rem;

		@include media-breakpoint-up(md) {
			padding: 0 5rem;
		}	
	
		.featured-case-study {
			position: relative;
			display: block;
			overflow: hidden;

			a {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 200;			
			}

			@include hover-focus-active {
				
				img, video {
					transition: all .2s ease-out;
					transform: scale(1.1);
					opacity: 0.5;
				}
				.case-study__view {
					display: block;
					opacity:1;
					transform: translateY(0rem);
				}
				.case-study__bottom-title {
				bottom: 1rem;
				}
				
			}

			img {
				width: 100%;
				height: 100%;
				display: block;
				transition: 0.2s;
				transform: translate3d(0, 0, 0);
				backface-visibility: hidden;
				perspective: 1000;	
				object-fit: cover;
			}

		}

		.featured_case-study__large {
			position: relative;
			display: block;
			overflow: hidden;
		}

		.featured_case-study__content {
			position: absolute;
			overflow: hidden;
			height: 50%;
			width: 100%;
			bottom: 0;
			background: linear-gradient(0deg,rgb(19, 20, 28),transparent);
		}
	}
	.featured-case-study__background {
	}

	.featured-case-study--small {
		overflow: hidden;
		position: relative;

		.featured-case-study__content {
			width: 100%;
			max-width: 100%;

	}
}


	// Clients

	.clients {
		padding: 6rem 0;
	}

	.clients__images {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.clients__image {
		height: 45px;
		width: auto;
		padding: 0 0.6rem;
		opacity: 0.5;
		transition: opacity 0.2s;

		@include hover-focus-active {
			opacity: 1;
		}

		@include media-breakpoint-up(md) {
			height: 50px;
		}

		@include media-breakpoint-up(lg) {
			height: 69px;
			padding: 0 1rem;
		}

	}

	// Articles

	.articles {

	}

		.articles__article {
			padding: 0;
			background-color: #000;
		}

		a.articles__link {
			width: 100%;
			height: 100%;

			color: #fff;

			@include hover-focus-active {
				color: $brand-primary;

				.articles__image {
					filter: grayscale(10%);
					opacity: 0.5;
				}
			}
		}

			.articles__image {
				display: block;
				filter: grayscale(70%);
				transition: 0.3s;
				opacity: 0.3;
			}

			.articles__content {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				padding: 0 10%;

				h2 {
					font-size: 4vw;

					@include media-breakpoint-up(md) {
						font-size: 2vw;
					}
				}
			}

				.articles__date {
					font-size: 3vw;

					@include media-breakpoint-up(md) {
						font-size: 1.5vw;
					}
				}

	
	// Articles
	.cta {
		h2 {
			text-transform: none;
			@include font-size(30);

			@include media-breakpoint-up(md) {
				@include font-size(54);
			}
		}
		p {
			color: #fff;
		}
	}
	

	// Contact

	.contact {
		img {
			min-height: 400px;
			min-width: 100%;
			max-width: 9999px;
			width: auto;
		}
	}

	.contact__content {
		padding: 0 1rem;
		width: 100%;
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		color: #fff;

		h2 {
			margin-bottom: 1rem;
		}

		p {
			max-width: 700px;
			margin: 0 auto 2rem;
		}

	}
}