.container-fluid {
    padding: 0px;
}

.container {
	@include media-breakpoint-down(md) {
		padding: 0;
	}
}

.row {
	//margin-right: 0;
	//max-width: 100%;
	//overflow: hidden;
}