//////////////////////
// Menu 
//////////////////////

.site-header {
	position: relative;
	width: 100%;
	z-index: 200;

	text-align: center;

	@include media-breakpoint-up(md) {
		position: absolute;
	}

	.site-header__brand {
		display: block;
		width: 100px;
		margin: $grid-gutter-width*2 $grid-gutter-width*3;

		.vn-logo {
			display: block;
			padding: 0.5rem 0;
			overflow: visible;
		}

		.vn-logo__initials {
			#v, #n {
				fill: $brand-black;

				@include media-breakpoint-up(md) {
					fill: $brand-black;
				}
			}
		}
		.vn-logo__initials-light {
			#v, #n {
				fill: $brand-black;

				@include media-breakpoint-up(md) {
					fill: $white;
				}
			}
		}
		
		@include media-breakpoint-up(md) {
			display: block;
			float: left;
			margin: 0 0 0 1rem;
			width: 130px;
		}
	}

	.site-header__branding {
		text-align: center;
		width: 100px;
		display: none;

		@include media-breakpoint-up(md) {
			display: inline-block;
			width: 180px;
			margin-right: 6rem;
		}

		@include media-breakpoint-up(lg) {
			display: inline-block;
			width: 200px;
			margin-left: 16rem;
			margin-right: 0;
		}
	}

	.mobile-header-btn {
		border:1px solid $brand-black;
		text-transform: uppercase;
		@include font-size(10);
		display: inline-block;
		letter-spacing: 2px;
		padding:.5rem 1rem;
		position:absolute;
		top:0;
	}

	.nav-primary {
		
		text-transform: uppercase;
		@include font-size(14);
		position: relative;

		@include media-breakpoint-up(md) {
			float: right;
			margin-right: 1rem;
			margin-top: .5rem;
		}

		.nav {
			display: none;
			margin: 0;
			padding: 0;

			@include media-breakpoint-up(lg) {
				display: block;
			}
		}

		.nav-dark {
			li {
				@include media-breakpoint-up(md) {
					a {
						color: $brand-black;
					}
				}
			}
		}

		li { 

				list-style: none;

				@include media-breakpoint-up(md) {
					float: left;
					margin-right: $grid-gutter-width / 2;
				}
				
				&:first-child {
				 	a {
						background-color: $brand-black;
					}
				}

				&:nth-child(2) {
					a {
						background-color: $brand-blue;
					}
				}
				&:nth-child(3) {
					a {
						background-color: $brand-red-light;
					}
				}
				&:nth-child(4) {
					a {
						background-color: $brand-orange-light;
					}
				}
				&:nth-child(5) {
					a {
						background-color: $brand-green;
					}
				}
				&:nth-child(6) {
					a {
						background-color: $brand-blue;
					}
				}

				@include media-breakpoint-up(md) {
					&:nth-child(n){
						a {
							background-color: transparent;
						}
					}
				}

				&:first-child {
					a {
						@include hover-focus-active {
							color: $brand-green;
						}
					}
			   }

				&:nth-child(2) {
					a {
						@include hover-focus-active {
							color: $brand-red-light;
						}
					}
				}
				&:nth-child(3) {
					a {
						@include hover-focus-active {
							color: $brand-blue;
						}
					}
				}
				&:nth-child(4) {
					a {
						@include hover-focus-active {
							color: $brand-black;

							@include media-breakpoint-up(md) {
								color: $brand-orange-light;
							}
						}
					}
				}
				&:nth-child(5) {
					a {
						@include hover-focus-active {
							color: $brand-green;
						}
					}
				}
				&:nth-child(6) {
					a {
						@include hover-focus-active {
							color: $brand-blue;
						}
					}
				}

				&.active:nth-child(2) {
					a {
						@include media-breakpoint-up(md) {
							color: $brand-red-light;
						}
					}
				}
				&.active:nth-child(3) {
					a {
						@include media-breakpoint-up(md) {
							color: $brand-blue;
						}
					}
				}
				&.active:nth-child(4) {
					a {
						@include media-breakpoint-up(md) {
							color: $brand-orange-light;
						}
					}
				}
				&.active:nth-child(5) {
					a {
						@include media-breakpoint-up(md) {
							color: $brand-green;
						}
					}
				}
				&.active:nth-child(6) {
					a {
						color: $brand-blue;
					}
				}
		}

		a {
			display: inline-block;
			height: 44px;
			padding: 0 1.2rem;
			// background-color: #fff;
			width: 100%;

			line-height: 46px;
			color: $white;
			font-family: $font-family-sans-serif-bold;
			font-weight: 600;
			letter-spacing: 3px;
			@include font-size(11);

			@include media-breakpoint-up(md) {
				width: auto;
			}

			@media (min-width: 900px) {
				padding: 0.5rem 1rem 0.5rem 1rem;
			}

			@include hover-focus-active {
				color: #fff;
			}

			&.menu-btn {
				background-color: #000;
				color: #fff;
			}
		}
	}
}

.menu-btn-holder {
	position: absolute;
	top: 18px;
	right: $grid-gutter-width*3;	
}

#nav-icon {
  width: 23px;
  height: 23px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

/* Icon 4 */

#nav-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 7px;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 14px;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  transform: rotate(45deg);
  //top: -3px;
  left: 4px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 16px;
  left: 4px;
}

.gradient-line {
	width: 100%;
	height: 4px;
	background: rgb(97,209,218);
	background: linear-gradient(90deg, rgba(97,209,218,1) 0%, rgba(243,108,108,1) 50%, rgba(235,178,31,1) 100%);
}
