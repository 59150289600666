// Grid settings
$grid-gutter-width-base: 0.375rem; //6px
$grid-gutter-width: $grid-gutter-width-base;

// Colors

$brand-black: #1B1A18;
$brand-grey: #62696f;
$brand-red: #c15a5e;
$brand-red-light: #F36C6C;
$brand-orange: #f4a61d;
$brand-orange-light: #EBB21F;
$brand-green: #cdd633;
$brand-blue: #61D1DA;
$brand-blue-dark: #409caa;

$brand-primary: $brand-blue;

$gray-light: #e5e5e5;
$gray-dark: #282828;


$body-color: #1B1A18;
$body-bg: #FFFDF7;

$link-color: $brand-red-light;

.brand-red {
  color: $brand-red;
}
.brand-blue {
  color: $brand-blue;
}
.brand-orange {
  color: $brand-orange;
}
.bg-brand-black {
  background-color: $body-color;
}
.overflow-hidden {
 overflow: hidden;
}
.relative {
  position: relative;
}
// Typography

$font-size-base: 1.25rem;

$font-family-sans-serif: "montregular", sans-serif;
$font-family-sans-serif-bold: "montbold", sans-serif;
$font-family-headings: "gazpacho-bold", Georgia, serif;
$font-family-headings-light: "gazpacho-light", Georgia, serif;
$font-family-condensed: "Open Sans Condensed", sans-serif;

$headings-font-weight:       200;
$body-font-weight:       500;

.montbold {
  font-family: $font-family-sans-serif-bold;
}

// Spacing
$spacer:   1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;
$spacers: (
  0: (
    x: 0,
    y: 0
  ),
  1: (
    x: ($spacer-x * .25),
    y: ($spacer-y * .25)
  ),
  2: (
    x: ($spacer-x * .5),
    y: ($spacer-y * .5)
  ),
  3: (
    x: $spacer-x,
    y: $spacer-y
  ),
  4: (
    x: ($spacer-x * 1.5),
    y: ($spacer-y * 1.5)
  ),
  5: (
    x: ($spacer-x * 3),
    y: ($spacer-y * 3)
  ),
  6: (
    x: ($spacer-x * 6),
    y: ($spacer-y * 6)
  )
)
