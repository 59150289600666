////////////////////////////////////
// Case Studies Archive
////////////////////////////////////
 
.post-type-archive-case-study {
	.page-header {
		text-align: center;

	}
}

.case-study-container {
	padding-left: 1rem;
	padding-right: 1rem;

	@include media-breakpoint-up(md) {
		padding-left: 4rem;
		padding-right: 4rem;
	}
}

.case-study {
	position: relative;
	margin-bottom: $grid-gutter-width;
	height: 100vw;
	overflow: hidden;
	padding: .6rem;

  @include media-breakpoint-up(md) {
    height: 57vw;
  }

	img, video {
    width: 100%;
		height: 100%;
		display: block;
		transition: 0.2s;
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
		perspective: 1000;	
		object-fit: cover;
	}

	video {
		display: none;
	}

	h2 {
		color: #fff;
	}

	@include media-breakpoint-up(md) {
		height: 27vw;

		video {
			display: block;
		}
		video + img {
			display: none;
		}
	}
}

	a.case-study__link {
		height: 100%;
		position: relative;
		display: block;
		background-color: $body-color;
		overflow: hidden;
		@include hover-focus-active {
		.case-study__content {
			display: block;

        h2 {
          // margin-bottom: 0;
        }

        span {
          display: block;
        }
			}
			img, video {
				transition: all .2s ease-out;
				transform: scale(1.1);
				opacity: 0.5;
			}
		}
	}

		.case-study__category {
			font-family: $font-family-headings;
			letter-spacing: 3px;
			text-transform: uppercase;
			@include font-size(12);
		}

		.case-study__content {
			position: absolute;
			height: 50%;
			width: 100%;
			bottom: 0;
			background: linear-gradient(0deg,rgb(19, 20, 28),transparent);

			h2 {
				@include font-size(18);

				@include media-breakpoint-up(md) {
					@include font-size(24);
					text-transform:none;
					letter-spacing:0px;
				}
			}
		}

			.case-study__date {
				@include font-size(18);
			}

    .case-study__bottom-title {
      color: #fff;
	  position: absolute;
	  bottom: 0;
	  left: 0;
	  padding: 0.5rem 1.5rem;
	  transition: all .2s ease-out;

	  @include media-breakpoint-up(md) {
		padding: 1rem 2rem;
	  }

	  .case-study__name {
		font-family: $font-family-headings;
		@include font-size(20);
		line-height: 22px;
		margin: 0.1rem 0;

		@include media-breakpoint-up(md) {
			@include font-size(24);
			line-height: 28px;
		}
	  }
	  .case-study__type {
		font-family: $font-family-sans-serif;
		@include font-size(14);
	  }
	  .case-study__cat {
		@include font-size(10);
		text-transform: uppercase;
		letter-spacing: 1px;
	  }
	  .case-study__view {
		@include font-size(11);
		text-transform: uppercase;
		letter-spacing: 1px;
		opacity:0;
		transform: translateY(-1rem);
		transition: all .2s ease-out;
	  }

	  a:hover & {
		bottom: 1rem;

		.case-study__view {
			display: block;
			opacity:1;
			transform: translateY(0rem);
		}
      }

    }

.case-study--large {
	h2 {
		text-transform:none;
		letter-spacing:0px;
		@include font-size(22);

		@include media-breakpoint-up(md) {
			@include font-size(28);
		}
	}
}

section.page__contact-section {
		text-align: center;
		overflow: hidden;

		color: #fff;

		img {
			min-height: 270px;
			width: 100vw;
			max-width: 9999px;
		}

		.archive-case-study__contact__content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;

			.button, h2 {
				margin-bottom: 0;
				
				vertical-align: middle;

				@include media-breakpoint-up(md) {
					display: inline-block;
				}
			}

			h2 {
				margin-bottom: 2rem;
				@include font-size(30);
				text-transform: none;

				@include media-breakpoint-up(md) {
					@include font-size(30);
					letter-spacing: 0px;
					margin-right: 2rem;
					margin-bottom: 0;
				}

				@include media-breakpoint-up(lg) {
					@include font-size(44);
				}

				
			}
		}
	}

////////////////////////////////////
// Single Case Study
////////////////////////////////////

.single-case-study {
	
}

.single-case-study__header {
	@include media-breakpoint-up(md) {
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
		}
	}
}

.single-case-study__container {
	position: relative;
}

.single-case-study__content {

	p {
		text-align:left;
		@include font-size(12);
		color: #fff;

		@include media-breakpoint-up(md) {
			@include font-size(14);
		}
		
	}

	h2 {
		@include font-size(32);
		margin-bottom: 0.5rem;
		color: #fff;
		
		@include media-breakpoint-up(md) {
			margin-bottom: 1rem;
			@include font-size(42);
		}
	}
	h4 {
		@include font-size(18);
		margin-bottom: 1rem;
		color: #fff;
		font-family: $font-family-headings-light;
		
		@include media-breakpoint-up(md) {
			@include font-size(26);
		}
	}

	padding: 4rem 2rem;

	@include media-breakpoint-up(md) {
		padding: 4rem 4rem;
		text-align:left;
	}

	@include media-breakpoint-up(xl) {
		padding: 6rem 8rem;
		text-align:left;
	}

}
.thick {
		font-weight:700;
		color: $brand-black;
		font-family: $font-family-condensed;
	}

.single-case-study__heading {
	h1 {
		@include font-size(52);
		@include media-breakpoint-up(md) {
			@include font-size(70);
		}
	}
	p {
		max-width: 800px;
		margin: 0 auto;
	}
	.subtitle {
		@include font-size(15);
	}
}

ul.single-case-study__products {
	color: $body-color;
	list-style: none;
	padding: 0;
}

li.single-case-study__product {
	position: relative;
	display: block;
	padding: 0 0.5rem;
	@include font-size(10);
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family:$font-family-sans-serif-bold;

	@include media-breakpoint-up(md) {
		display: inline;
	}
}

.single-case-study__hero {
	margin-top: 10rem;
	padding-left: 1rem;
	padding-right: 1rem;

	@include media-breakpoint-up(md) {
		margin-top: 8rem;
		padding-left: 3rem;
		padding-right: 0;
	}

	.copy_overlay {
		z-index: 10;
	}

	img, video {
		object-fit: cover;
    	width: 100%;
		min-height: 240px;
		margin-top: -8rem;

		@include media-breakpoint-up(md) {
			margin-top: -6rem;
		}
	}

	video {
		display: none;
	}

	@include media-breakpoint-up(md) {
		min-width:100%;
		height: auto;
		
		video {
			display: block;
		}
		video + img {
			display: none;
		}
	}
}


.singe-case-study__copy {
	line-height:1.5rem;
	color:$brand-grey;
	font-weight: $body-font-weight;

	@include media-breakpoint-up(md) {
		@include font-size(14);
	}
}

.single-case-study__services-container {

	padding: 4rem 2rem;

	@include media-breakpoint-up(md) {
		padding-top: 4rem;
		padding-bottom: 4rem;
		padding-left: 3rem;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 6rem;
		padding-bottom: 6rem;
		padding-left: 6rem;

	}
}

.single-case-study__services-heading {
	margin-top:0rem;
	margin-bottom: 1.5rem;

	@include font-size(32);

	@include media-breakpoint-up(md) {
		text-align: left;
		@include font-size(24);
	}
}

ul.single-case-study__services {
	list-style: none;
	padding: 0;
	margin-bottom: 1rem;
	color: $body-color;
	@include font-size(10);
	letter-spacing: 1px;
	text-transform: uppercase;
	font-family:$font-family-sans-serif-bold;	
}

li.single-case-study__service {
	position: relative;
	margin-bottom: 0rem;
	padding-bottom: 1rem;
	text-align: left;
	border-bottom: 1px solid rgb(219, 219, 219);
}

.single-case-study__slide {
	img, video {
		margin-bottom: 1rem;
	}
}

.single-case-study__slide-description {
	@include font-size(15);
	padding: 0 1rem;

	@include media-breakpoint-up(md) {
		padding: 0;
	}

	a {
		border-left: 1px solid #000;
		padding-left: 0.5rem;
		margin-left: 0.3rem;
	}

}

.video-title {
	display: block;
	text-align: center;
	font-family: $font-family-headings;
	text-transform: uppercase;
	letter-spacing: 2px;
	@include font-size(16);
	font-weight: 800;
	margin-top:1rem;
}
.video-subtitle {
	margin-bottom:1.5rem;
	display: block;
	text-align: center;
	font-family: $font-family-headings;
	text-transform: uppercase;
	letter-spacing: 2px;
	@include font-size(12);
}

.single-case-study__post-links {
	background-color: rgba(0, 0, 0, 0.98);
	margin-bottom: 0rem;
	display: none;
	

	@include media-breakpoint-up(md) {
		display: block;		
	}

	a {
		padding: 0 3rem 0 3rem;
		height: 74px;
		line-height: 74px;
		background-color: rgba(0, 0, 0, 0.98);
		float: left;

		@include font-size(12);
		font-weight: 600;
		letter-spacing: 2px;
		color: #fff;
		text-transform: uppercase;
		
	}
}

.single-case-study__post-link-next {
	float: left;
	transition: 0.2s;
	transform: translateX(-1rem);

	i {
		border:1px solid #fff;
		border-radius:5rem;
		padding: 0.4rem 0.44rem;
		@include font-size(10);
		margin-right: 0.3rem;
	}
	@include hover-focus-active {
		transform: translateX(0);
	}

	@include media-breakpoint-up(md) {
		margin-bottom: 0;
	}
}
.single-case-study__post-link-all {

	i {
		border:1px solid #fff;
		border-radius:5rem;
		padding: 0.4rem 0.3rem 0.4rem 0.6rem;
		@include font-size(10);
		
	}

	float: right;
	transition: 0.2s;
	transform: translateX(1rem);
	@include hover-focus-active {
		transform: translateX(0);
	}
}

.project-link {
		display: block;
		font-family: $font-family-sans-serif-bold;
		background-color: $body-color;
		text-align: center;
		@include font-size(10);
		border-radius: 50px;
		text-transform: uppercase;
		letter-spacing: 1px;
		padding: 1rem 3rem;

		@include media-breakpoint-up(md) {
			display: inline-block;
		}
}
a.project-link {
	color: #fff;
}
a:hover.project-link {
	color: #222930;
	background-color: #60c8d8;
}

.post-link__arrow-left {
	float: left;
	width: 0; 
	height: 0; 
	border-top: 37px solid transparent;
	border-bottom: 37px solid transparent;
	border-right: 64px solid rgba(0, 0, 0, 0.98);
}
	
.post-link__arrow-right {
	float: left;
	content: '';
	width: 0; 
	height: 0; 
	border-top: 37px solid transparent;
	border-bottom: 37px solid transparent;
	border-left: 64px solid rgba(0, 0, 0, 0.98);
}

.tabs {

	width: 100%;
	margin-top: 1rem;

	&__header {
		background-color: $body-color;
		font-family: $font-family-sans-serif-bold;
		text-transform: uppercase;
		letter-spacing: 1px;
		@include font-size(12);
		padding: 1rem 0;
		color: #fff;
		border: none;
		display: block;
		width: 100%;

		.button {
			display:none;
		}

		@include media-breakpoint-up(md) {
			display: none;
			color: $brand-black;
		}
	}

	&__buttons {
		opacity: 0;
		background-color: #0d0d12;
		position: absolute;
		transition: opacity 0.2s;
		pointer-events: none;
		

		&.active {
			opacity: 1;
			pointer-events: auto;
		}

		@include media-breakpoint-up(md) {
			position: relative;
			background-color: transparent;
			opacity: 1;
			pointer-events: auto;
		}
	}

	@include media-breakpoint-up(md) {
		position: relative;
	}
}
