@mixin font-size($sizeValue: 1) {
	font-size: $sizeValue * 1px;
	font-size: $sizeValue / 16 * 1rem;
}

@mixin hover-focus-active {
  @if $enable-hover-media-query {
    &:focus,
    &:active,
    &.active {
      @content
    }
    @include hover { @content }
  }
  @else {
    &:focus,
    &:active,
    &:hover,
    &.active {
      @content
    }
  }
}