.iphorm-outer .iphorm-elements .iphorm-element-wrap-text input,
.iphorm-outer .iphorm-elements .iphorm-element-wrap-captcha input,
.iphorm-outer .iphorm-elements .iphorm-element-wrap-email input,
.iphorm-outer .iphorm-elements .iphorm-element-wrap-password input,
.iphorm-outer .iphorm-elements .iphorm-element-wrap select,
.iphorm-outer .iphorm-elements .iphorm-element-wrap textarea {
	width: 100%;
	background-color: $gray-lightest;
	border: none;
}

label {
	margin-bottom: 0;
}

.gform_wrapper .ginput_container_select {
    position:relative;
}
.gform_wrapper select.gfield_select {
    padding:0;
    height:3.125rem;
    margin:0;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    background-color:transparent;
    border:1px solid $brand-black;
    font-size:15px;
    font-size:.9375rem;
    text-indent:1rem;
    color:$brand-black;
}

.gform_wrapper .ginput_container_select::after {
    content:'\f078';
    display:block;
    position:absolute;
    top:0;
    right:-1px;
    width:3.125rem;
    height:3.125rem;
    background-color:$brand-black;
    pointer-events:none;
    font-size:12px;
    color:#fff;
    line-height:3.125rem;
    text-align:center;
    font-family:FontAwesome;
}