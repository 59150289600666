a.button.disco-btn {
	border-radius:40px;
	font-weight:600;
	letter-spacing:2px;
	@include font-size(12);
}

.site-footer {
	padding: 3rem 3rem 1rem;
	position: relative;
	background-color: rgba(0, 0, 0, 0.98);
	color:#fff;
}

.site-footer__brand {
	display: block;
	margin: 0 auto 2rem;
	width: 120px;
	height: 87px;

	@include media-breakpoint-up(md) {
		position: absolute;
		top: 0;
		transform: translate(-50%, -50%);
		left: 2rem;
		transform: translateY(-50%);
	}
}

.site-footer__left {
	@include font-size(18);
	text-align: center;
	margin-bottom: 2rem;

	@include media-breakpoint-up(md) {
		text-align: left;
		margin-bottom: 0;
	}
}

.site-footer__left__top {
	margin-bottom: 0.3rem;
}

	.site-footer__socials {

		display: inline;
		margin-right: 1rem;

		i {
			color: #fff;
			transition: color 0.2s;
			margin-right: 0.2rem;
		}

		a {
			&:nth-child(1) {
				i {
					@include hover-focus-active {
						color: $brand-red-light;
					}
				}
			}
			&:nth-child(2) {
				i {
					@include hover-focus-active {
						color: $brand-orange;
					}
				}
			}
			&:nth-child(3) {
				i {
					@include hover-focus-active {
						color: $brand-orange-light;
					}
				}
			}
			&:nth-child(4) {
				i {
					@include hover-focus-active {
						color: $brand-green;
					}
				}
			}
		}
	}

	.site-footer__phone-number {
		font-weight: 900;
	}

	.site-footer__copyright {
		@include font-size(11);
		font-weight: 500;
	}

	.site-footer__right {
		@include font-size(18);
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

		.site-footer__contact {
			text-align: center;
			margin-bottom: 1rem;

			@include media-breakpoint-up(md) {
				margin-left: 2rem;
				text-align: right;
			}

			@include media-breakpoint-up(xl) {
				display: inline-block;
			}

			h3 {
				color: $white;
				@include font-size(12);
				letter-spacing: 1px;
				text-transform: uppercase;
				font-family:$font-family-sans-serif-bold;	
				margin-bottom:0.2rem;
			}

			a {
				@include font-size(12);
				color: $brand-blue;
			}
		}