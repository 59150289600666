.home {
	.owl-dots {

		position: absolute;
		bottom: 30px;
		width: 100%;

		text-align: center;
		-webkit-tap-highlight-color: transparent;

		@include media-breakpoint-up(md) {
			bottom: 10%;
		}

		@include media-breakpoint-up(lg) {
			bottom: 8%;
		}
		
		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: 10px;
				height: 10px;
				margin: 5px 4px;
				background: $gray-dark;
				display: block;
				-webkit-backface-visibility: visible;
				transform: rotate(45deg);
				transition: background-color 0.2s;
			}

			&.active,
			&:hover {
				span {
					background: $brand-primary;
				}
			}
		}
	}
}

.single-case-study {
	.owl-dots {

		float: right;
		-webkit-tap-highlight-color: transparent;
		width: auto;
		position: relative;
		top: -100%;
		transform: translateY(-100%);
		padding: 0 1rem;

		@include media-breakpoint-up(md) {
			padding: 0;
		}

		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: 10px;
				height: 10px;
				margin-left: 5px;
				background: $gray-dark;
				display: block;
				-webkit-backface-visibility: visible;
				transition: background-color 0.2s;
				border-radius: 50%;
			}

			&.active,
			&:hover {
				span {
					background: $brand-red-light;
				}
			}
		}
	}
}

.featured-case-studies__bottom {
  .owl-dots {
    margin-top: 1rem;
    position: relative;
    bottom: auto;
    right: auto;
  }
}

.owl-carousel {
	.slide {
		transform: translate3d(0,0,0);
	}
}